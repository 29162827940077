jQuery(function($) {
    $('.dipi_carousel').each(function(index, value) {
        let $this = $(this);
        var data = value.querySelector('.dipi-carousel-main').dataset;
        let selector = "." + $this.attr('class').split(' ').join('.') + " .swiper-container";
        var navigation = "on" === data.navigation && {
            nextEl: ".dipi_carousel .dipi-sbn" + data.ordernumber,
            prevEl: ".dipi_carousel .dipi-sbp" + data.ordernumber
        };

        var dynamicbullets = ('on' == data.dynamicbullets) ? true : false;
        var pagination = "on" === data.pagination && {
            el: ".dipi_carousel .dipi-sp" + data.ordernumber,
            clickable: true,
            dynamicBullets: dynamicbullets,
            dynamicMainBullets: 1
        };

        var cfe = {
            rotate: Number(parseInt(data.rotate)),
            stretch: 5,
            depth: 100,
            modifier: 1,
            slideShadows: data.shadow === 'on',
        };

        let mySwiper = new Swiper(selector, {
            slidesPerView: Number(data.columnsphone),
            spaceBetween: Number(data.spacebetween_phone),
            speed: Number(data.speed),
            loop: "on" === data.loop,
            // grabCursor: true,
            autoplay: "on" === data.autoplay && {
                delay: data.autoplayspeed,
                reverseDirection: data.autoplayreverse === 'on',                
            },
            effect: data.effect,
            coverflowEffect: "coverflow" === data.effect ? cfe : null,
            navigation: navigation,
            pagination: pagination,
            centeredSlides: "on" === data.centered,
            slideClass: "dipi_carousel_child",
            wrapperClass: "dipi-carousel-wrapper",
            setWrapperSize: true,
            observer: true,
            observeParents: true,
            observeSlideChildren: true,
            breakpoints: {
                768: {
                    slidesPerView: Number(data.columnstablet),
                    spaceBetween: Number(data.spacebetween_tablet) > 0 ? Number(data.spacebetween_tablet) : Number(0),
                },
                981: {
                    slidesPerView: Number(data.columnsdesktop),
                    spaceBetween: Number(data.spacebetween) > 0 ? Number(data.spacebetween) : Number(0),
                }
            }
        });

        if ('on' === data.pauseonhover && 'on' === data.autoplay) {
            $this.find('.swiper-container').on('mouseenter', function(e) {
                mySwiper.autoplay.stop();
            });

            $this.find('.swiper-container').on('mouseleave', function(e) {
                mySwiper.autoplay.start();
            });
        }
        $this.find(".dipi-carousel-main.dipi_loading").removeClass("dipi_loading").show();

        /* lightbox */
        $this.find('.dipi-carousel-image').magnificPopup({
            type: 'image',
            removalDelay: 500,
            fixedContentPos: false,
            gallery: {
                enabled: true,
                navigateByImgClick: true,
                tCounter: '%curr% / %total%'
            },
            mainClass: 'mfp-fade',
            zoom: {
                enabled: true, //! et_pb_custom.is_builder_plugin_used,
                duration: 500,
                opener: function(element) {
                    return element.find('img');
                }
            },
            autoFocusLast: false,
            image: {
                verticalFit: true,
                titleSrc: function(item) {
                    let title = "";
                    if (item.el.attr('data-title')) {
                        title += item.el.attr('data-title');
                    }
                    if (item.el.attr('data-caption')) {
                        title += "<small class='dipi_masonry_gallery_caption'>" + item.el.attr('data-caption') + "</small>";
                    }
                    return title;
                }
            },
            disableOn: function() {
                /*if ($(this).hasClass("show_lightbox")) {
                    return false;
                }*/
                console.log('disableon', $this)
                if (window.matchMedia("(max-width: 767px)").matches) {
                    return $this.find(".dipi-carousel-main").hasClass("show_lightbox_phone")
                } else if (window.matchMedia("(max-width: 980px)").matches) {
                    return $this.find(".dipi-carousel-main").hasClass("show_lightbox_tablet");
                }
                else {
                    return $this.find(".dipi-carousel-main").hasClass("show_lightbox");
                }           
            },
            callbacks: {
                open: function() {
                    var swidth=(window.innerWidth-$(window).width());
                    jQuery('body').addClass('noscroll');
                    /* To fix jumping, need to set scroll width as margin-right */
                    jQuery('body').css('margin-right', swidth + 'px');
                },
                close: function() {
                    jQuery('body').removeClass('noscroll');
                    jQuery('body').css('margin-right', 0);
                }
            }
        });

        $this.find('.dipi_carousel_child .dipi-carousel-image').on('click', function(e) {
            e.preventDefault();
            //$.magnificPopup.hide();
            return true;
        });
    });

});